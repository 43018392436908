import React from "react";
import styles from "./Bio.module.css";
import { getGravatarURL } from "../utils/getGravatarURL";

function Bio(props) {
  let photoURL = getGravatarURL({
    email: "voicetyping.xyz@gmail.com",
    size: 56,
  });

  return (
    <div
      className={`
      ${styles.Bio}
      ${props.className || ""}
    `}
    >
      <img src={photoURL} alt="voicetyping.xyz logo" />
      <p>
       A voice typing browser extension allows users to enter text by speaking instead of typing, increasing efficiency and accessibility.
      </p>
    </div>
  );
}

export default Bio;
