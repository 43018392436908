export default {
  // The blog's title as it appears in the layout header, and in the document
  // <title> tag.
  title: "VoiceTyping blogs",

  author: "VoiceTyping Support",
  description:
    "A voice typing browser extension allows users to enter text by speaking instead of typing, increasing efficiency and accessibility.",

  // The number of posts to a page on the site index.
  indexPageSize: 10,
};
